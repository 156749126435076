<template>
  <div >
    <navBar  :title="title"></navBar>
    <div class="image-container">
      <img src="../../../assets/images/1791637026300_.pic_hd.jpg" alt="">
    </div>

    <div class="modify">
      <div class="step" v-if="step">
        <div class="modify-password">
          <!-- <span>公司名称</span>
          <span>:</span> -->
          <div>
            <el-input placeholder="请输入公司名称" v-model="companyInfo.name"></el-input>
          </div>
        </div>
        <div class="modify-password">
          <!-- <span>公司税号</span>
          <span>:</span> -->
          <div>
            <el-input
              @input="inputs(companyInfo.creditCode)"
              placeholder="请输入公司税号"
              v-model="companyInfo.creditCode"
            ></el-input>
          </div>
        </div>
        <div class="submit">
          <el-button @click="next" type="primary">下一步</el-button>
        </div>
      </div>
      <div class="step" v-else>
        <div class="modify-password">
          <!-- <span>修改账号</span>
          <span>:</span> -->
          <div>
            <el-input placeholder="修改账号" v-model="userInfo.loginPhone"></el-input>
          </div>
        </div>
        <div class="modify-password">
          <!-- <span>修改密码</span>
          <span>:</span> -->
          <div>
            <el-input placeholder="修改密码" v-model="userInfo.password"></el-input>
          </div>
        </div>
        <div class="submit">
          <el-button @click="submit" type="primary">确认</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import navBar from '../navBar'
import { Notify } from 'vant';
export default {
  name: '',
  components:{
    navBar
  },
  data () {
    return {
      title: {
        name: '找回账号',
        status: false,
      },
      step:true,

      msg: '',
      userInfo: {
        id: "",
        loginPhone: "",
        password: "",
      },
      companyInfo: {
        name: "",
        creditCode: ""
      },
    }
  },
  created () {
  },
  computed: {
  },
  methods: {
    inputs (str) {
      this.companyInfo.creditCode = str.replace(/(\w{4})(?=\w)/g, '$1 ').replace(/(\d{4})(?=\d)/g, '$1 ').toUpperCase();
    },
    submit () {
      if (this.userInfo !== "") {
        this.$request.put(this.$api.amendNumber, this.userInfo).then(res => {
          // 17701246400
          if (res.status == 200) {
            Notify({ type: 'success', message: '修改成功，请重新登录！' });
            this.$router.push("/mobileIndex");
            sessionStorage.removeItem('user-token')
            sessionStorage.clear()
            window.location.reload()
          }
        });
      }
    },
    next () {
      var url = this.$api.checkUserInfo;
      if (this.companyInfo.name == ""){
          Notify({ type: 'warning', message:'请输入公司名称或稅号'});
      }

      if (this.companyInfo !== "")
        this.$request.post(url, this.companyInfo).then(res => {
          if (res.status == 200 && res.data != "") {
            this.step = false;
            console.log('reeeee', res);
            this.userInfo.id = res.data;
            this.userInfo.password = ''
          } else {
            Notify({ type: 'danger', message: '请输入正确的公司名称/税号，如忘记请联系管理人员!' });
          }
        });



    },
  }
}
</script>


<style scoped lang="less">
.image-container{
    width: 100%;
    height: 75px;
    margin:50px 0px;
    position: relative;
    img{
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      width: 195px;
      height: 60px;
    }
  }
.modify {
  // width: 300px; 
  // height: 300px;
  padding: 0 30px 30px 30px;

  border-radius: 5px !important;
  > .title {
    width: 100%;
    height: 65px;
    margin-bottom: 30px;
    line-height: 65px;
    text-align: center;
    color: #000;
    font-size: 20px;
  }
  .modify-password {
    // padding: 0 25px;
    // display: flex;
    margin-bottom: 15px;
    // align-items: center;
    // justify-content: space-around;
    > span {
      color: #000;
      font-size: 16px;
    }
    /deep/.el-input__inner {
      width: 100%;
      border-radius: 3px !important;
      height: 38px;
    }
  }
  .submit {
    display: flex;
    justify-content: center;
    margin-top: 25px;
    // padding: 0 25px;
    /deep/.el-button {
      width: 100%;
      border-radius: 3px !important;
    }
  }
}
</style>
